﻿package com.thwackstudio.components

import androidx.compose.runtime.Composable
import com.thwackstudio.ProjectInformation
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.css.CSSTextShadow
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun AndroidProjectGridItem(path: PageContext, item: ProjectInformation) {
    Column(
        modifier = menu_item_style.toModifier()
//            .backgroundImage(backgroundImage = CSSImage.of(url(item.icon)))
//            .backgroundSize(backgroundSize = BackgroundSize.Contain)
//            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .onClick {
                it.preventDefault()
                val (linkDescription, link) = item.link
                path.router.navigateTo(link + ".html",
                    openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB)
            }
//                        .styleModifier {
//                            property("pointer-events", "none")
//                        }
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
//        Box(modifier = Modifier.align(alignment = Alignment.CenterHorizontally  )) {
        Image(
            item.icon, modifier = Modifier
                .borderRadius(10.px)
                .fillMaxWidth()
                .aspectRatio(1)
        )
//        }
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .weight(1)
                .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                .margin(top = 10.px)
        ) {
            Text(item.title)
        }
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fontSize(Res.Dimens.FONT_SIZE_SMALL)
                .weight(1)
                .textAlign(TextAlign.Justify)
                .margin(top = 10.px)
        ) {
            Text(item.shortDescription)
        }
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                .margin(top = 10.px)
        ) {
            val (linkDescription, link) = item.link
            Link(link + ".html", linkDescription, 
                openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE, 
                openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE)
        }

//                Button(
//                    onClick = {
//                        path.router.navigateTo("/?t=test")
//                    }, modifier = Modifier.boxShadow(0.px, 0.px, 15.px)
//                        .zIndex(1)
//                        .styleModifier {
//                            property("pointer-events", "auto")
//                        }
//                ) {
//                    Text("")
//                }
    }
}

@Composable
fun UnityProjectGridItem(path: PageContext, item: ProjectInformation) {
    Column(
        modifier = menu_item_style.toModifier()
//            .backgroundImage(backgroundImage = CSSImage.of(url(item.icon)))
//            .backgroundSize(backgroundSize = BackgroundSize.Contain)
//            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .onClick {
                it.preventDefault()
                val (linkDescription, link) = item.link
                path.router.navigateTo(link,
                    openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE)
            }
//                        .styleModifier {
//                            property("pointer-events", "none")
//                        }
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            item.icon, modifier = Modifier
                .borderRadius(10.px)
                .fillMaxWidth()
                .aspectRatio(1)
        )
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .weight(1)
                .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                .margin(top = 10.px)
        ) {
            Text(item.title)
        }
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fontSize(Res.Dimens.FONT_SIZE_SMALL)
                .weight(1)
                .textAlign(TextAlign.Justify)
                .margin(top = 10.px)
        ) {
            Text(item.shortDescription)
        }
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                .margin(top = 10.px)
        ) {
            val (linkDescription, link) = item.link
            Link(link, linkDescription,
                openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE)
        }

    }
}

val menu_item_style by ComponentStyle {
    val elements = CSSTransition(property = "all", 500.ms)
    base {
        Modifier.transition(arrayOf(elements))
            .padding(all = 20.px)
            //.backgroundColor(Res.Colors.BG_WHITE)
            .boxShadow(
                color = Colors.Blue.copy(alpha = 40),
                offsetX = 5.px,
                offsetY = 5.px,
                blurRadius = 15.px,
                spreadRadius = 5.px
            )
            .borderRadius(r = 3.percent)
            .textAlign(TextAlign.Center)
    }
//    cssRule(":hover") {
    hover {
        Modifier.transition(arrayOf(elements))
            .color(Colors.Black)
            .textShadow(CSSTextShadow(0.px, 0.px, 5.px, Colors.Black.copy(alpha = 200)))
            .scale(1.05)
    }
}