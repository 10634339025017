﻿package com.thwackstudio.pages.codriven

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.graphics.Image
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CoDrivenGallery(images: List<String>, intervalMillis: Long = 3000) {

    var currentIndex by remember { mutableStateOf(0) }
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(Unit) {
        while (true) {
            delay(intervalMillis)
            coroutineScope.launch {
                currentIndex = ((currentIndex + 1) % images.size)
            }
        }
    }


    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(auto)
            .border(2.px)
            .borderRadius(12.px)
            .overflow(Overflow.Hidden)
    ) {
        Row(
            modifier = Modifier
                .width((100).percent)
                .height(30.percent)
                //.translateX((-currentIndex * 50).percent)
                //.transition(CSSTransition("transform", intervalMillis.ms, TransitionTimingFunction.Linear))
                .styleModifier {
                    property("transition", "all (${intervalMillis})ms ease-out allow-discrete")
                    property("transition-duration", "${intervalMillis}ms")
                    property("transform", "translate(${-currentIndex * 50}%,0)")
                }
        ) {
            images.forEach { image ->
                Image(
                    modifier = Modifier
                        .translateX(50.percent)
                        .weight(1)
                        .width((50).percent),
                    src = image,
                    alt = "Gallery image"
                )
            }
        }

    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(auto)
            .border(2.px)
            .borderRadius(12.px)
            .overflow(Overflow.Hidden)
    ) {
        Row(
            modifier = Modifier.align(Alignment.BottomCenter).padding(8.px),
            horizontalArrangement = Arrangement.Center
        ) {
            images.indices.forEach { index ->
                Box(
                    modifier = Modifier
                        .size(if (index == currentIndex) 12.px else 8.px)
                        .backgroundColor(if (index == currentIndex) Color.white else Color.gray)
                        .borderRadius(50.percent)
                        .margin(4.px)
                )
            }
        }
    }
}


