﻿package com.thwackstudio.pages.codriven

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.overlay.Overlay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun CoDrivenUIContent() {
    val path = rememberPageContext()

    val selectedFeatureItem = remember { mutableStateOf<CoDrivenFeatureInfoItem?>(null) }
    Column(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(Res.Colors.BG_MIDDLE)
            .margin(20.px)
    ) {
        FeatureDescription(path)
        CoDrivenGallery(
            List(30) { Res.Images.GAL_PATH.replace("_", "${it + 1}") },
            3000
        )
        FeaturesPanel(path) {
            selectedFeatureItem.value = it
        }
    }
    if (selectedFeatureItem.value != null) {
        SelectedFeatureDialog(selectedFeatureItem, path)
    }
}

@Composable
fun FeatureDescription(path: PageContext) {
    Column(
        modifier = Modifier.margin(32.px)
            .overflow(Overflow.Visible)
    ) {

        CoDrivenFeatureItemRow(
            path, CoDrivenFeatureInfoItem(
                "CoDriven Advanced UI", Res.Images.CODRIVEN_UI, "" to "",
                "I am a professional Android developer with many years of experience, but I love creating games. During my game development journey, I encountered a UI-related problem and decided to fix it by creating CoDriven Advanced UI. I could conveniently create UI for apps and games using code in an Android environment. I decided to add this capability to this tool, thanks to this, you will be able to create UI by simply using code, which is generally easier than any existing solutions. Maybe just for me but try it and you will know what I mean."
            )
        ) {

        }
    }
}

@Composable
fun SelectedFeatureDialog(
    selectedFeatureItem: MutableState<CoDrivenFeatureInfoItem?>,
    path: PageContext
) {
    Overlay(modifier = Modifier.onClick {
        selectedFeatureItem.value = null
    }.styleModifier {
        property("pointer-events", "default")
    }) {
        Box(
            modifier = Modifier.fillMaxSize().background(rgba(0, 0, 0, 0.5f)), // Tło całej strony (opcjonalnie)
            contentAlignment = Alignment.Center
        ) {
            Box(
                modifier = Modifier.width(70.percent).height(70.percent)
                    .background(Color.transparent).padding(32.px)
            ) {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    selectedFeatureItem.value?.let {
                        CoDrivenFeatureItemRow(path, selectedFeatureItem.value!!) {
                            selectedFeatureItem.value = null
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun ColumnScope.FeaturesPanel(path: PageContext, onClickFeatureItem: (CoDrivenFeatureInfoItem?) -> Unit) {
    Box {

    }
    Column(
        modifier = Modifier.backgroundColor(Res.Colors.BG_TRANSPARENT).height(auto = auto).fillMaxWidth().weight(1)
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .styleModifier {
                    backgroundImage("url('codriven/images/features_bg.png')")
                    backgroundSize("cover")       // Dopasowanie obrazka do rozmiaru Column
                    backgroundPosition("center")  // Wyśrodkowanie obrazka
                    backgroundRepeat("no-repeat") // Brak powtarzania obrazka
                },
        ) {
            FeaturesHeader()
            SimpleGrid(
                numColumns = numColumns(1, sm = 1, md = 2, lg = 2, xl = 2),
                modifier = Modifier.alignContent(AlignContent.Stretch).columnGap(20.px).fillMaxWidth().weight(1f)
                    .rowGap(20.px).backgroundColor(Res.Colors.BG_TRANSPARENT).boxShadow(
                        color = Colors.Black.copy(alpha = 10), blurRadius = 50.px, spreadRadius = 50.px
                    ).padding(all = 20.px)
            ) {
                for (feature in codrivenFeatures) {
                    CoDrivenFeatureItemColumn(path, feature, onClickFeatureItem)
                }
            }
        }
    }
}

@Composable
private fun FeaturesHeader() {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier.fillMaxWidth().fontSize(Res.Dimens.FONT_SIZE_LARGE_V3).margin(20.px)
            .textAlign(TextAlign.Center).fontWeight(800)
            .color(Color.white)
    ) {
        Text("Features")
    }
}

@Composable
fun ColumnScope.Header() {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(auto)
            .padding(left = 20.px)
            .backgroundColor(Res.Colors.BG_DARK),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
//        Image(
//            Res.Images.CODRIVEN_UI, modifier = Modifier.width(20.percent).aspectRatio(1f)
//        )

        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.backgroundColor(Res.Colors.BG_TRANSPARENT).fillMaxWidth()
                .fontSize(Res.Dimens.FONT_SIZE_LARGE_V5).margin(20.px).color(Res.Colors.FONT_WHITE)
                .textAlign(TextAlign.Center).fontWeight(800)
        ) {
            Text("CoDriven Advanced UI")
        }
    }
}
