﻿package com.thwackstudio.utils

import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

object Res {
    object Images {
        const val PROFILE_PHOTO = "images/profilephoto.png"
        const val THWACK_STUDIO_LOGO = "images/logo.png"
        const val CODRIVEN_UI = "codriven/images/codriven_advanced_UI_logo.png"

        
        // codriven
        const val GRAPH_EDITOR = "images/codrivenui/graph_editor.png"
        const val THEMES = "images/codrivenui/themes.gif"
        const val CONTROLS = "images/codrivenui/advanced_game_scene2.gif"
        const val MULTIPLAYER_GAME = "images/codrivenui/game.gif"
        const val TRANSLATION_EDITOR = "images/codrivenui/translation_editor.png"

        const val FEATURES_BG = "codriven/images/features/features_bg.png"
        const val FEATURES_DOCUMENTATION = "codriven/images/features/documentation.png"
        const val FEATURES_GRAPH_EDITOR = "codriven/images/features/graph_editor.png"
        const val FEATURES_LANG_CHANGE = "codriven/images/features/lang_change.png"
        const val FEATURES_MULTIPLAYER_GAME = "codriven/images/features/MultiplayerGame.png"
        const val FEATURES_MULTIPLAYER_GAME_SCR = "codriven/images/features/MultiplayerGame_screens.png"
        const val FEATURES_SCREEN_MANAGER = "codriven/images/features/screen_manager.png"
        const val FEATURES_SETTINGS = "codriven/images/features/settings.png"
        const val FEATURES_STYLING_CONTROLS = "codriven/images/features/styling_controls.png"
        const val FEATURES_THEMES = "codriven/images/features/themes_support.png"
        const val FEATURES_TRANSLATION_EDITOR = "codriven/images/features/translation_editor.png"
        
        const val GAL_PATH = "codriven/images/features/scr/multiplayer_.jpg"

    }
    object Sites {
        const val CODRIVEN_UI = "codrivenui"
    }
    
    object Colors {
        val BG_BLACK = rgb(0,0,0)
        val BG_DARK = rgb(11,18,24)
        val BG_MIDDLE = rgb(16,29,38)
        val BG_LIGHT = rgb(25,42,55)
        val BG_BRIGHT = rgb(209,235,255)
        val BG_WHITE = rgb(255,255,255)
        val BG_TRANSPARENT = rgba(255,255,255, 0)
        
        val FONT_BLACK = rgb(0,0,0)
        val FONT_WHITE = rgb(255,255,255)
        val FONT_BLUISH_LIGHT = rgb(209,235,255)
        val FONT_ORANGE = rgb(192,98,76)
        val FONT_ORANGE_BRIGHT = rgb(255,111,105)
        val FONT_YELLOW = rgb(255,211,0)
    }
    
    object Dimens {
        val MARGIN_SMALL = 10.px
        val MARGIN_MEDIUM = 20.px
        val MARGIN_LARGE = 30.px

        val FONT_SIZE_LARGE_V5 = 70.px
        val FONT_SIZE_LARGE_V4 = 60.px
        val FONT_SIZE_LARGE_V3 = 50.px
        val FONT_SIZE_LARGE_V2 = 40.px
        val FONT_SIZE_LARGE = 30.px
        val FONT_SIZE_MEDIUM = 20.px
        val FONT_SIZE_SMALL = 16.px
    }
}