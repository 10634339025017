package com.thwackstudio.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun CodrivenuiPage() {
    com.varabyte.kobweb.compose.foundation.layout.Column {
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("﻿# CoDriven Advanced UI")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                com.varabyte.kobweb.silk.components.graphics.Image("upload://c6CgjYZNOsBKube9JwGR9HT7mHn.jpeg", "")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Download demo:")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://drive.google.com/file/d/1gVtBivnYPzrnXkpvV6pYUh3QBitUnQ9i/view?usp=drive_link") {
                org.jetbrains.compose.web.dom.Text("https://drive.google.com/file/d/1gVtBivnYPzrnXkpvV6pYUh3QBitUnQ9i/view?usp=drive_link")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("DOCUMENTATION (IN PROGRESS)")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://stefanwyszynski.github.io/CoDriven-Advanced-UI/#/") {
                org.jetbrains.compose.web.dom.Text("https://stefanwyszynski.github.io/CoDriven-Advanced-UI/#/")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Lastest Update:")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("- currently there are no updates")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Introduction")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("CoDriven UI is an extension of the UI Toolkit that will allow you to create a complete UI, style it without USS code, handle events simply, change language, manage changing screens, or create translations for your text. No Uss is required but you still can use it.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Why I created this")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I am a professional Android developer with many years of experience, but I love creating games. During my game development journey, I came across a problem related to UI and decided to fix it by creating CoDriven Advanced UI. In android environment, I could conveniently create UI for apps and games using code. I decided to add this capability in this tool, thanks to this, you will be able to create UI by simply using code, which is generally easier than any existing solutions. Maybe just for me but try it and you will know what I mean.")
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.graphics.Image("..%2Fpublic%2Fcodriven%2Fimages%2Fcodriven_advanced_UI_logo.png", "codriven_advanced_UI_logo.png")
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.graphics.Image("codriven/images/features/multiplayer_fps_screen1.png", "Fps game image1")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.graphics.Image("codriven/images/features/multiplayer_fps_screen2.png", "Fps game image2")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.graphics.Image("codriven/images/features/multiplayer_fps_screen3.png", "Fps game image2")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.graphics.Image("codriven/images/features/multiplayer_fps_screen4.png", "Fps game image2")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("iframe", "width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/7LXTpXGxhpc\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen=\"\"")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.youtube.com/watch?v=7LXTpXGxhpc") {
                org.jetbrains.compose.web.dom.Text("https://www.youtube.com/watch?v=7LXTpXGxhpc")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Description video")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("iframe", "width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/P4l8i4sC-IY\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen=\"\"")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.youtube.com/watch?v=P4l8i4sC-IY") {
                org.jetbrains.compose.web.dom.Text("https://www.youtube.com/watch?v=P4l8i4sC-IY")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("⭐ CoDriven Advanced UI is an extension of the UI Toolkit that will allow you to create a complete UI, style it without USS code (using style builder in code), handle events simply, change language and create translations for your game texts, and manage changing screens.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("No Uss is required to style controls, but you still can use it. No UI Toolkit is required to create your views because it will be generated automatically from your code, but you can still use the UI Toolkit.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("⭐CoDriven Advanced UI will resolve problems like how to:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("easy style your controls with multiple themes support, changed runtime")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("animate your GUI with automatically generated USS for your code, so for example, you will be able to animate and change style over time when the mouse is hovering over your control,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("handle input events for the whole game menu,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("bind sound to events,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("create multiple screens and move between them easily,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("pass events to UI globally")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("make readable/reusable/separated code with menus,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("multiple examples with fully working multiplayer game and menu")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("⭐Full list of features")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("full source code included")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("easy and powerful styling in the c# code using probably all modifiers available in USS - no more raw CSS/USS pseudocode (it will be generated automatically based on your styles),")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("build-in nodes editor with screen preview.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("language change for your UI and Translation Editor (built-in using CoDriven Advanced UI itself :))")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("theming and changing of the current theme runtime (just call simple function)")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("add your custom Editors in Unity using CoDriven Advanced UI. You can check how (build-in) Translation Editor was made - because it was made using it.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("easy event handling! (you can handle events on part of your screen, screen as a whole, globally, or even in many places at the same time)")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("CoDriven Advanced UI contains most of UI Toolkit controls and a few more, like a floating window, and a draggable panel. This will likely be improved in the future because it's still under development")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("implemented with an observer pattern in mind to observe changes in UI or trigger UI changes from external events - all by observable fields, but by default, it doesn't require that")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("multiple scenes with examples like a fully working multiplayer game, or simple one theme, simple two themes, scene with screen part,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Included: FULLY working 3D multiplayer FPS (mirror/LAN) with: main menu and in-game menu 5 game scenes with maps and main/in-game menu")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("passing player data to UI by event callback")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("screen changing by separations of logic. All game screens will be handled by the screen manager which will contain your screen classes. Screens will contain sections (\"ScreenPart\") that can be reused anywhere in the game.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("So for example: the settings menu could be added to in game menu by just one single line of code.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("build-in helper functions for your game and tools for multiplayer with a mirror")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("persistent and also observable fields for settings for your options. Assign data to the field and forget about it - it will be saved. Add your savable settings.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("behaviors classes - allow you to assign specific behaviors for your controls. Currently, there is one behavior - floating window behavior that will make control movable/draggable by mouse, like windows in the operating system.")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("⭐ Features of 3D multiplayer FPS (mirror/LAN):")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("play with your friend,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("fully working menu with settings changes like resolution, fullscreen/windowed, refresh rate, render scale, vsync, FOV, sound, music, mouse sensitivity, and more. Settings are saved automatically. You can add your settings.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("grappling hook weapon created from scratch using math in code for springs (without using the build in spring classes), so you can do more,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("multiple sounds and ambient music for maps,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("car driving logic: spring camera attached to the seat and shooting while driving + free camera rotation + FPP/TPP camera during driving 😄,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("2 drivable car models included,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("rigged and animated player character,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("weapon recoil using spring,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("weapon attached to the hand,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("the sound of the engine associated with the car's speed,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("jumping logic,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("sounds for actions,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("health status,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("multiple weapons like pistol, machinegun, grenade launcher, sniper with simple zooming, rocket launcher (all model included),")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("weapon recoil and muzzle flashes,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("player footsteps manager,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("turn player flashlight on/off,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("5 different maps,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("models like trees, houses, grass, etc.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("2 enemies following player and shooting,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("enemies manager that will allow you to add more enemies and spawn them randomly,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("network helper: lan server discovery code, join, host game, start server,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("easy to understand and separated logic in multiplayer,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("observable PlayerPrefs preferences of type like Bool, Int, String, and Float that are saved automatically")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("and more :D")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Just try it!")
        }
    }
}
