﻿package com.thwackstudio

import com.thwackstudio.utils.Res


data class ProjectInformation(
    val title: String,
    val icon: String,
    val link: Pair<String, String>,
    val shortDescription: String,
    val images: List<String>,
    val description: String
)

val androidPorjectsInfoItems = arrayListOf(
    ProjectInformation(
        "Parafia Bełżyce 2024 (2024)",
        "images/parish_2024_logo.png",
        "For more information click here" to "parish_belzyce_2024.html",
        "This android application for the local parish in Bełżyce completely remade from scratch using Jetpack Compose. The application will probably display fresh data based on the Parish in Bełżyce website in the future.",
        arrayListOf(""),
        ""
    ),
    ProjectInformation(
        "Parafia Bełżyce (2018)",
        "images/parish_belz_180.jpg",
        "For more information click here" to "parish_belzyce.html",
        "This android application for the local parish in Bełżyce. The application displays fresh data based on the Parish in Bełżyce website.",
        arrayListOf(""),
        ""

    ),
    ProjectInformation(
        "Awesome Blocks (2017)",
        "images/awesome_blocks_icon.png",
        "For more information click here" to "awesome_blocks.html",
        "Awesome Blocks are our latest game. This game is a combination of standard match 3 and tetris like games.",
        arrayListOf(""),
        ""
    ),
    ProjectInformation(
        "Snake 3D Revenge (2012)",
        "images/snake_3d_rev_icon.png",
        "For more information click here" to "snake_3d_revenge.html",
        "Our very first game in 3D. If you like classic snake game then you should like it.",
        arrayListOf(""),
        ""
    ),
    ProjectInformation(
        "Creature Blocks (2012)",
        "images/monster_blocks_icon.png",
        "For more information click here" to "monster_blocks.html",
        "Creature Blocks is a remake/clone of Monster Blocks. This game is a combination of standard match 3 and tetris like games.",
        arrayListOf(""),
        ""
    ),
    ProjectInformation(
        "Knight Defense (2016)",
        "images/knight_defense_icon.png",
        "For more information click here" to "knight_defense.html",
        "Knight defense is a combination of standard match 3 and defense games.",
        arrayListOf(""),
        ""
    ),
    ProjectInformation(
        "ADB Autoconnect (2012)",
        "images/adb_autoconnect_icon.png",
        "For more information click here" to "adb_autoconnect.html",
        "The ADB Autoconnect (currently unavaliable) is an application that will allow you to debug/install android application without using an USB cable",
        arrayListOf(""),
        ""
    )
)

val unityPorjectsInfoItems = arrayListOf(
    ProjectInformation(
        title ="UI Compose (2025)",
        icon = Res.Images.CODRIVEN_UI,
        link = "For more information click here" to "?site=${Res.Sites.CODRIVEN_UI}",
        shortDescription = "CoDriven UI is an extension of the UI Toolkit that will allow you to create a complete UI, style it without USS code, handle events simply, change language, manage changing screens, or create translations for your text. No Uss is required but you still can use it.",
        images = arrayListOf(Res.Images.CODRIVEN_UI),
        description = "aaa"
    )
)
