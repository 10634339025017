package com.thwackstudio.pages

import androidx.compose.runtime.*
import com.thwackstudio.androidPorjectsInfoItems
import com.thwackstudio.components.*
import com.thwackstudio.pages.codriven.CoDrivenUIContent
import com.thwackstudio.unityPorjectsInfoItems
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.VerticalDivider
import com.varabyte.kobweb.silk.components.layout.numColumns
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun Index() {
    // JSON.parse<CC>("responseText")
    val path = rememberPageContext() // Path updated when browser URL changes
    var subsiteID = ""
    if (path.route.queryParams.containsKey("site")) {
        subsiteID = path.route.queryParams.getValue("site")
    }
    mainMenu(path, subsiteID.lowercase())
}

@Composable
fun mainMenu(path: PageContext, subsiteID: String) {
    mainMenu {
        when (subsiteID) {
            Res.Sites.CODRIVEN_UI -> {
                CoDrivenUIContent()
                false
            }
            else -> {
                Row(
                    modifier = Modifier.height(auto = auto).fillMaxWidth().weight(1)
                        .backgroundColor(Res.Colors.BG_TRANSPARENT)
                ) {
                    AndroidProjectsPanel(path)
                    VerticalDivider()
                    UnityProjectsPanel(path)
                }
                true
            }
        }
    }
}

@Composable
fun mainMenu(showPageContent: @Composable ColumnScope.() -> Boolean) {
    var showUserInfoPanel by remember { mutableStateOf(true) }
    Row(modifier = Modifier.fillMaxSize()) {
        UserProfile(Modifier.weight(0).fillMaxHeight(), showUserInfoPanel) {
            showUserInfoPanel = !showUserInfoPanel
        }

        Column(modifier = Modifier.fillMaxSize()) {
            Column(
                modifier = Modifier.backgroundColor(Res.Colors.BG_TRANSPARENT)
                    .height(auto = auto).fillMaxWidth().weight(1)
            ) {
                showUserInfoPanel = showPageContent()
            }
            Footer()
        }
    }
}

@Composable
fun RowScope.AndroidProjectsPanel(path: PageContext) {
    Column(
        modifier = Modifier.fillMaxWidth(50.percent)
            .backgroundColor(Res.Colors.BG_TRANSPARENT)
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .backgroundColor(Res.Colors.BG_TRANSPARENT)
                .fillMaxWidth()
                .fontSize(Res.Dimens.FONT_SIZE_LARGE_V3)
                .margin(20.px)
                .textAlign(TextAlign.Center)
                .fontWeight(800)
        ) {
            Text("Android")
        }
        SimpleGrid(
            numColumns = numColumns(1, sm = 2, md = 2, lg = 2, xl = 2),
            modifier = Modifier
                .alignContent(AlignContent.Stretch)
                .columnGap(20.px)
                .fillMaxWidth()
                .backgroundColor(Res.Colors.BG_TRANSPARENT)
                .weight(1f)
                .rowGap(20.px)
                .boxShadow(
                    color = Colors.Black.copy(alpha = 10),
                    blurRadius = 50.px,
                    spreadRadius = 50.px
                )
                .padding(all = 20.px)
        ) {
            for (item in androidPorjectsInfoItems) {
                AndroidProjectGridItem(path, item)
            }
        }
    }
}

@Composable
fun RowScope.UnityProjectsPanel(path: PageContext) {
    Column(modifier = Modifier.fillMaxWidth(50.percent)) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .fillMaxWidth()
                .fontSize(Res.Dimens.FONT_SIZE_LARGE_V3)
                .margin(20.px)
                .textAlign(TextAlign.Center)
                .fontWeight(800)
        ) {
            Text("Unity 3D")
        }
        SimpleGrid(
            numColumns = numColumns(1, sm = 1, md = 1, lg = 1, xl = 1),
            modifier = Modifier
                .alignContent(AlignContent.Stretch)
                .columnGap(20.px)
                .fillMaxWidth()
                .weight(1f)
                .rowGap(20.px)
                .backgroundColor(Res.Colors.BG_WHITE)
                .boxShadow(
                    color = Colors.Black.copy(alpha = 10),
                    blurRadius = 50.px,
                    spreadRadius = 50.px
                )
                .padding(all = 20.px)
        ) {
            for (item in unityPorjectsInfoItems) {
                UnityProjectGridItem(path, item)
            }
        }
    }
}
