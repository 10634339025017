﻿package com.thwackstudio.components

import androidx.compose.runtime.Composable
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaFaceSmile
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Col
import org.jetbrains.compose.web.dom.Text

@Composable
fun ColumnScope.Footer() {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(auto = auto)
            .padding(all = 20.px)
            .color(Res.Colors.FONT_YELLOW)
            .fontSize(20.px)
            .fontWeight(600)
            .backgroundColor(Res.Colors.BG_DARK)
            .weight(1),
//        horizontalArrangement = Arrangement.Center,
//        verticalAlignment = Alignment.CenterVertically
                horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
            Row(
                modifier = Modifier.margin(10.px).width(auto)
            ) {
                Text("© All Rights Reserved. Stefan Wyszyński." )
            }
        Row(
            modifier = Modifier.margin(10.px).width(auto)
        ) {
            Text("Created by Stefan Wyszyński in Kotlin Kobweb (KMP compose)")
            Text(" ")
            FaFaceSmile()
        }
    }
}