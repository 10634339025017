package com.thwackstudio.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun CodPage() {
    com.varabyte.kobweb.compose.foundation.layout.Column {
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("﻿**")
            com.varabyte.kobweb.silk.components.graphics.Image("upload://c6CgjYZNOsBKube9JwGR9HT7mHn.jpeg", "")
            org.jetbrains.compose.web.dom.Text("** ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("UI Compose")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Download demo:")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://drive.google.com/file/d/1gVtBivnYPzrnXkpvV6pYUh3QBitUnQ9i/view?usp=drive_link") {
                org.jetbrains.compose.web.dom.Text("https://drive.google.com/file/d/1gVtBivnYPzrnXkpvV6pYUh3QBitUnQ9i/view?usp=drive_link")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("DOCUMENTATION (IN PROGRESS)")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://stefanwyszynski.github.io/ui-compose-doc/#/") {
                org.jetbrains.compose.web.dom.Text("https://stefanwyszynski.github.io/ui-compose-doc/#/")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Lastest Update:")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("- added enemies that spawn randomly, so you can fight with your friend against them, the demo version contains that :)")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.youtube.com/watch?v=7LXTpXGxhpc") {
                org.jetbrains.compose.web.dom.Text("https://www.youtube.com/watch?v=7LXTpXGxhpc")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Description video")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.youtube.com/watch?v=P4l8i4sC-IY") {
                org.jetbrains.compose.web.dom.Text("https://www.youtube.com/watch?v=P4l8i4sC-IY")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Introduction")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("UI compose is an extension of the UI Toolkit that will allow you to create a complete UI, style it without USS code, handle events simply, change language, manage changing screens, or create translations for your text. No Uss is required but you still can use it.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Why I created this")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Currently, it is under development. UI Toolkit is a great solution but it was not enough for me so I made it better for me and maybe for you too (I hope :)).")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I am from an Android development background where we can create UI using Jetpack compose which is generally easier than any existing solutions. This is why I thought that it would be great to have a similar tool in Unity. This is how UI compose was made. Of course, this is not 100% like in Android but the concept is similar.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Features")
            }
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("full source code included")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("styling in the code using probably all modifiers available in USS but using build-in functions - no more CSS/USS,")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("build in nodes with screen preview. You can connect them by actions and use these actions to move between screens (if you want)")
                }
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.graphics.Image("upload://iCMI6X0MUM5wdufsgIiDfmWFC3X.jpeg", "")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("language change and translation editor (built-in using UI compose itself :))")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.graphics.Image("upload://rtJLc9GLz0KRJ15C6e5BkXbU1fd.gif", "")
            org.jetbrains.compose.web.dom.Text(" You can add your custom Editors in Unity. You can check how (build-in) Translation Editor was made - because it was made with UI Compose :) ")
            com.varabyte.kobweb.silk.components.graphics.Image("upload://bSq8WE8o0VszTE3PkdEuEb2FN1h.png", "")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("theming and changing of the current theme")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.graphics.Image("upload://dK4WChSh9YqUuUnUweZDpGZeIYv.gif", "")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("event handling! (you can handle events on part of your screen, screen as a whole, globally, or event in many places at the same time)")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("UI compose contains most of the UI Toolkit controls and a few more, like a floating window, and a draggable panel. This will be probably improved in the future because it's still under development")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("implemented with observer pattern in mind to observe changes in UI or trigger UI changes from external events - all by observable fields, but by default it doesn't require that")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("multiple scenes with examples like simple one theme, simple two themes, the scene with screen part, and game included with a few game scenes with maps and a main menu")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("passing player data to UI by event callback")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("screen changing by separations of concerns. All game screens will be handled by the screen manager which will contain your screen classes. Screens will contain sections (\"ScreenPart\") that can be reused anywhere in the game. So for example: the settings menu section could be added to in game menu by just one single line of code. You can omit sections code but it is recommended.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("build-in helper functions for your game and tools for multiplayer with a mirror")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("persistent and also observable fields for settings for your options. Assign data to the field and forget about it.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("behaviors classes - allow you to assign specific behaviors for your controls. Currently, there is one behavior floating window behavior that will make control movable/draggable by mouse like windows in the operating system.")
                }
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Included 3D multiplayer FPS (mirror/LAN) contains:")
            }
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("fully working menu with settings changes like resolution/refresh rate, sound, music, mouse sensitivity, and more")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("grappling hook weapon created from scratch using math in code for springs (without using the build in spring classes), so you can do more,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("car driving logic: spring camera attached to the seat and shooting while driving + free camera rotation + FPP/TPP camera during driving,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("car models included")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("weapon recoil using spring,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("weapon attached to the hand")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("jumping")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("sounds for actions,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("health status")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("multiple weapons like pistol, machinegun, grenade launcher, a sniper with simple zooming, and rocket launcher (models included with blender files .blend)")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("weapon recoil")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("maps for the game and simple models like trees, houses,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("you can play with friends on LAN :)")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.graphics.Image("upload://5uaU4smWKYg50ozvyOD9PAgz2ak.jpeg", "")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.graphics.Image("upload://35jJHv2o1lyWBsiE9FjuEWfii28.jpeg", "")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.graphics.Image("upload://l3BDE9pWgFeFz5EuOAnp0Z9E8zm.jpeg", "")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.graphics.Image("upload://3sE8pVpta8qB7tPlI57Huv9Ndv8.jpeg", "")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Limitations of the current version of UI Compose:")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("UI compose is styling controls by generating USS code in the background and has the same limitations as UI Toolkit (in this version). The system will generate all styles for all your themes and selectors from the code to the Resources/UssStyles folder and it will be loaded runtime.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Examples")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Styling")
            }
            org.jetbrains.compose.web.dom.Text(" of Toggle control for states: when the mouse is hovered, the control is disabled, or checked:")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""var cmToggle = new CmToggle(..);
cmToogle.modifiers.CheckMark(
     // create modifier for DEFAULT_STATE
     new CmModifierImageAndBackground(CmSelector.DEFAULT_STATE)
         .BorderWidth(0)
         .BorderRadius(60.px())
         .BorderColorRGBA(new Color32(255, 51, 4, 255))
 
         // Now add modifiers for HOVER state (DEFAULT_STATE modifiers are inherited)
         .____NextSelector(CmSelector.HOVER)
         .Scale(1.2f)
 
         // Now add modifiers for the DISABLED state (DEFAULT_STATE modifiers are inherited)
         .____NextSelector(CmSelector.DISABLED)
         .BackgroundColorRGBA(Color.white)
 
         // Now add modifiers for the CHECKED state (DEFAULT_STATE modifiers are inherited)
         .____NextSelector(CmSelector.CHECKED)
         .BackgroundColorRGBA(Color.gray)
);
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This will create USS code under the hood.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Simple example")
            }
            org.jetbrains.compose.web.dom.Text(" section of the screen with buttons to change the current theme \"CmScreenPart\" code")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""public class CmScreenPartTopSectionExampleSP : CmScreenPart<ExampleThemeBase>
    {

        public CmScreenPartTopSectionExampleSP(CmScreen<ExampleThemeBase> screen) : base(screen) {}

        public override void BindUIEventsOnStart(CmUIEventsHandler eventsHandler)
        {
            // play sound on mouse down example for all controls except containers
            eventsHandler.GenericMouseDown = (control) => { someMethodToPlaySound(); };
 
            // handle on button click for all buttons
            eventsHandler.ButtonClick = anyButton =>
            {
                if (anyButton.GetName().Equals("button_Theme_White"))
                {
                    // change theme to white
                    setCurrentTheme(new ThemeWhite());
                    MakeScreenContent();
                }
                if (anyButton.GetName().Equals("button_Theme_Black"))
                {
                    // change theme
                    setCurrentTheme(new ThemeTest());
                    MakeScreenContent();
                }
            };
        }

        // You will always provide content for your screen here or empty
        // invisible controls if you want to create a ghost :)
        public override CmControlBase MakeContent()
        {
            // Create and style row control (which is a horizontal panel)
            var row = new CmRow();

            // Style this panel to add padding, margins, graphics, colors,
            // layout vertical or horizontal etc (as in UI toolkit USS)
            GetTheme().StyleRowPanel(row);

            // magic strings all over but this is only example
            // create two buttons and add them to the row container
            row.AddContent(createButton("Theme white", "button_Theme_White"));
            row.AddContent(createButton("Theme black", "button_Theme_Black"));
            return row;
        }

        public CmButton createButton(string text, string name)
        {
            // create a button with a global event handler assigned from this window by
            // calling getUIEventsHandler()
            var button = new CmButton(text, getUIEventsHandler());

            // set button name
            button.SetName(name);
 
            // style button by current theme assigned (it may be ThemeWhite or ThemeBlack)
            // we call methods of ExampleThemeBase interface which is parent to ThemeWhite
            // or ThemeBlack implementations
            GetTheme().StyleButton(button);

            return button;
        }
    }
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("theme is for example your ExampleThemeBase interface (in the code above) and you will add methods here to style your buttons and then you will add implementations of that interface to make the code for theme ThemeWhite and ThemeBlack.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("The detailed documentation is ready in 70%")
            }
            org.jetbrains.compose.web.dom.Text(", but I haven't added it yet to any website or github. I'm very curious if you like it. Let me know. For me, this is a very good solution, but your opinion is very important.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I will describe how it works in detail in the video but when I put the documentation on the web you will know everything about it. The tool is large but I think it is really simple to use if you understand the basics and will help to create menus faster.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("For now, there is only:")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Discord:")
            }
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://discord.gg/kydGC5w7") {
                org.jetbrains.compose.web.dom.Text("https://discord.gg/kydGC5w7")
            }
        }
    }
}
