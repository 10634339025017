﻿package com.thwackstudio.components

import androidx.compose.runtime.Composable
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaGithub
import com.varabyte.kobweb.silk.components.icons.fa.FaLinkedin
import com.varabyte.kobweb.silk.components.icons.fa.FaReddit
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun UserProfile(modifier: Modifier, showUserInfoPanel: Boolean, onClick: () -> Unit) {
    val intervalMillis = 1000
    if (!showUserInfoPanel) {
        return
    }
    Column(
        modifier = modifier
            .width(if (showUserInfoPanel) 40.percent else 10.percent)
            .fillMaxHeight()
            .backgroundColor(Res.Colors.BG_MIDDLE)
            .padding(all = Res.Dimens.MARGIN_MEDIUM)
            .flex(0, 1)
            .styleModifier {
                property("transition", "all (${intervalMillis})ms ease-out allow-discrete")
                property("transition-duration", "${intervalMillis}ms")
                //property("transform", "translate(${if (showUserInfoPanel)0 else -50}%,0)")
            }
    ) {
        // image container
//        Image(
//            Res.Images.PROFILE_PHOTO, modifier = modifier
//                .fillMaxWidth()
//                .aspectRatio(1)
//                .borderRadius(r = 10.percent)
//                .flex(flexGrow = 0, flexShrink = 1).onClick {
//                    onClick()
//                }
////                .boxShadow(4.px, 4.px, Res.Dimens.MARGIN_SMALL, Res.Dimens.MARGIN_SMALL, Colors.Orange.copy(alpha = 50))
//        )

        // decription container
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(
                    left = Res.Dimens.MARGIN_SMALL,
                    top = Res.Dimens.MARGIN_MEDIUM,
                    right = Res.Dimens.MARGIN_SMALL
                )

        ) {
            TextItemSection("Name:", "Stefan Wyszyński")

            if (showUserInfoPanel) {
                TextItemSection(
                    "About me:", "Android application developer. As a hobby," +
                            " I create 3D projects in Unity 3D"
                )
            }
            SocialMedia(
                {
                    FaLinkedin()
                },
                "https://www.linkedin.com/in/stefan-wyszy%C5%84ski-903074167/",
                "Linkeedin"
            )
            SocialMedia(
                {
                    FaGithub()
                },
                "https://github.com/StefanWyszynski",
                "Github"
            )
            SocialMedia(
                {
                    FaReddit()
                },
                "https://www.reddit.com/user/Dynamic-dream-studio/",
                "Reddit"
            )
            TextItemSection(
                "Email:", "thwackstudio@gmail.com"
            )
            PrivacyAndTerms()
        }
    }
}

@Composable
private fun TextItemSection(headerTitle: String, headerDescription: String) {
    Row(
        modifier = Modifier
            .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
            .padding(top = Res.Dimens.MARGIN_SMALL)
            .color(Res.Colors.FONT_WHITE)
    ) {
        Text(headerTitle)
    }
    Row(
        modifier = Modifier.fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
            .flexWrap(FlexWrap.Nowrap)
            .padding(left = Res.Dimens.MARGIN_SMALL, top = Res.Dimens.MARGIN_SMALL, right = Res.Dimens.MARGIN_SMALL)
            .color(Res.Colors.FONT_BLUISH_LIGHT)
    ) {
        Text(headerDescription)
    }
}

@Composable
private fun SocialMedia(icon: @Composable () -> Unit, link: String, headerDescription: String) {
    Row(
        modifier = Modifier
            .margin(top = Res.Dimens.MARGIN_MEDIUM, right = Res.Dimens.MARGIN_MEDIUM)
            .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
            .color(Res.Colors.FONT_WHITE)
    ) {
        icon()
        Link(link, headerDescription, modifier = Modifier.margin(left = Res.Dimens.MARGIN_SMALL))
    }
}


@Composable
private fun PrivacyAndTerms() {
    Column(
        modifier = Modifier.margin(10.px).fillMaxWidth()
            .margin(top = Res.Dimens.MARGIN_MEDIUM, right = Res.Dimens.MARGIN_MEDIUM)
            .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
            .color(Res.Colors.FONT_WHITE)
    ) {
        Row {
            Text("PrivacyPolicy: \n")
        }
        Link(
            "privacy_policy.html.html", "Click here",
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
            openInternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        )
    }
    Column(
        modifier = Modifier.margin(10.px).fillMaxWidth()
            .margin(top = Res.Dimens.MARGIN_MEDIUM, right = Res.Dimens.MARGIN_MEDIUM)
            .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
            .color(Res.Colors.FONT_WHITE)
    ) {
        Row {
            Text("TermsOfConditions: \n")
        }
        Link(
            "terms_of_services.html.html", "Click here",
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
            openInternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        )
    }
    Column(
        modifier = Modifier.margin(10.px).fillMaxWidth()
            .margin(top = Res.Dimens.MARGIN_MEDIUM, right = Res.Dimens.MARGIN_MEDIUM)
            .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
            .color(Res.Colors.FONT_WHITE)
    ) {
        Row {
            Text("Created by Stefan Wyszyński in Kotlin Kobweb (KMP compose)")
            Text(" ")
            //FaFaceSmile()
        }
    }
}