﻿package com.thwackstudio.pages.codriven

import androidx.compose.runtime.Composable
import com.thwackstudio.components.menu_item_style
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.VerticalDivider
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

data class CoDrivenFeatureInfoItem(
    val title: String,
    val icon: String,
    val link: Pair<String, String>,
    val description: String
)

val codrivenFeatures = arrayListOf(
    CoDrivenFeatureInfoItem(
        title = "Multiple controls",
        icon = Res.Images.CONTROLS,
        link = "" to "",
        description = "CoDriven Advanced UI contains controls from UI Toolkit and a few more, like a floating window, " +
                "and a draggable panel. This will be improved in the future"
    ),
    CoDrivenFeatureInfoItem(
        title = "Screen manager",
        icon = Res.Images.FEATURES_SCREEN_MANAGER,
        link = "" to "",
        description = "All screens will be managed by Screen Manager. Screen manager will allow you to move between your screens by calling just one single method."
    ),
    CoDrivenFeatureInfoItem(
        title = "Easy styling with code - good bye USS :)",
        icon = Res.Images.FEATURES_STYLING_CONTROLS,
        link = "" to "",
        description = "Powerful styling in the c# code using probably all modifiers available from USS -" +
                " no more raw CSS/USS pseudocode. Just create your code and USS will be generated automatically"
    ),
    CoDrivenFeatureInfoItem(
        title = "Game settings",
        icon = Res.Images.FEATURES_SETTINGS,
        link = "" to "",
        description = "Built-in Multiplayer game example has working multiple settings that can be changed. This will help you to prepare your own game effortlessly"
    ),
    CoDrivenFeatureInfoItem(
        title = "Easy theming",
        icon = Res.Images.FEATURES_THEMES,
        link = "" to "",
        description = "Just create multiple themes for your controls and change theme runtime with single method call."
    ),
    CoDrivenFeatureInfoItem(
        title = "Fully working multiplayer game with menu",
        icon = Res.Images.FEATURES_MULTIPLAYER_GAME,
        link = "" to "",
        description = "included: FULLY working 3D multiplayer FPS (mirror/LAN) with main menu and in-game menu." +
                "This is fully working menu with multiple sections like map select, about, sound settings, " +
                "video settings, controls, lobby, in game pause, respawn, in game gui"
    ),
    CoDrivenFeatureInfoItem(
        title = "Build in graph editor",
        icon = Res.Images.FEATURES_GRAPH_EDITOR,
        link = "" to "",
        description = "Display your entire flow of screens from your game in a bigger picture w using nodes graph " +
                "editor. This way you can visually see the flow of your game screens"
    ),
    CoDrivenFeatureInfoItem(
        title = "Build in Translation editor",
        icon = Res.Images.FEATURES_TRANSLATION_EDITOR,
        link = "" to "",
        description = "Add your languages and translations for your strings in build in translation editor " +
                "created using this tool :). You can use translations by their ID in your GUI. You can easily change " +
                "current selected language with single method call"
    ),
    CoDrivenFeatureInfoItem(
        title = "Documeted source code and external documentation",
        icon = Res.Images.FEATURES_DOCUMENTATION,
        link = "see the documentation" to "https://stefanwyszynski.github.io/CoDriven-Advanced-UI/#/",
        description = "Full source code included, with documentation on the website"
    ),
    CoDrivenFeatureInfoItem(
        title = "Easy mouse event handling!",
        icon = "",
        link = "" to "",
        description = "You can handle all your events on part of your screen, screen as a whole, globally for all screens, " +
                "or the same event in many places at the same time. This is very flexible solution tested in real game."
    ),
    CoDrivenFeatureInfoItem(
        title = "Many additional features",
        icon = "",
        link = "" to "",
        description = "implemented with observer pattern in mind to observe changes in UI or trigger UI " +
                "changes from external events"
    ),
    CoDrivenFeatureInfoItem(
        title = "Pass your data to UI",
        icon = "",
        link = "" to "",
        description = "Easy passing player data to UI by event callback to display updated ui"
    ),
    CoDrivenFeatureInfoItem(
        title = "Unity Asset Store",
        icon = "",
        link = "Asset Store Link" to "https://assetstore.unity.com/packages/tools/gui/codriven-advanced-ui-multiplayer-game-311367",
        description = ""
    ),
    CoDrivenFeatureInfoItem(
        title = "Unity Discussions",
        icon = "",
        link = "Unity discussion" to "https://discussions.unity.com/t/released-codriven-advanced-ui-multiplayer-game/1610819",
        description = "Will be available soon"
    ),
)

@Composable
fun CoDrivenFeatureItemRow(
    path: PageContext,
    item: CoDrivenFeatureInfoItem,
    onClickFeatureItem: (CoDrivenFeatureInfoItem?) -> Unit
) {
    Row(
        modifier = menu_item_style.toModifier()
            .backgroundColor(Res.Colors.BG_WHITE)
            .onClick {
                onClickFeatureItem(item)
            },
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
//        verticalArrangement = Arrangement.Top,
//        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        if (!item.icon.isBlank()) {
            Column(
                modifier = Modifier.fillMaxWidth(90.percent),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Image(
                    item.icon, modifier = Modifier
                        .borderRadius(10.px)
                        .fillMaxWidth()
                        .height(auto)
                )
            }
            //HorizontalSpacer()
            VerticalSpacer()
        }
        Column(
            modifier = Modifier.fillMaxWidth(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .weight(1)
                    .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                    .fontWeight(800)
                    .margin(top = 10.px)
            ) {
                Text(item.title)
            }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                    .weight(1)
                    .textAlign(TextAlign.Justify)
                    .margin(top = 10.px)
            ) {
                Text(item.description)
            }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fontSize(Res.Dimens.FONT_SIZE_LARGE)
                    .margin(top = 10.px)
            ) {
                val (linkDescription, link) = item.link
                Link(
                    link, linkDescription,
                    openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                )
            }
        }

    }
}

@Composable
fun CoDrivenFeatureItemColumn(
    path: PageContext,
    item: CoDrivenFeatureInfoItem,
    onClickFeatureItem: (CoDrivenFeatureInfoItem?) -> Unit
) {
    Column(
        modifier = menu_item_style.toModifier()
            .backgroundColor(Res.Colors.BG_WHITE)
            .onClick {
                onClickFeatureItem(item)
            },
//        verticalAlignment = Alignment.CenterVertically,
//        horizontalArrangement = Arrangement.Center
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        if (!item.icon.isBlank()) {
            Column(
                modifier = Modifier.fillMaxWidth(90.percent),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Image(
                    item.icon, modifier = Modifier
                        .borderRadius(10.px)
                        .fillMaxWidth()
                        .height(auto)
                )
            }
            HorizontalSpacer()
            //VerticalSpacer()
        }
        Column(
            modifier = Modifier.fillMaxWidth(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .weight(1)
                    .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                    .fontWeight(800)
                    .margin(top = 10.px)
            ) {
                Text(item.title)
            }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fontSize(Res.Dimens.FONT_SIZE_MEDIUM)
                    .weight(1)
                    .textAlign(TextAlign.Justify)
                    .margin(top = 10.px)
            ) {
                Text(item.description)
            }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fontSize(Res.Dimens.FONT_SIZE_LARGE)
                    .margin(top = 10.px)
            ) {
                val (linkDescription, link) = item.link
                Link(
                    link, linkDescription,
                    openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                )
            }
        }

    }
}

@Composable
fun RowScope.VerticalSpacer() {
    Column(Modifier.width(22.px)) {}
    VerticalDivider()
    Column(Modifier.width(22.px)) {}
}

@Composable
fun ColumnScope.HorizontalSpacer() {
    Column(Modifier.height(12.px)) {}
    HorizontalDivider()
    Column(Modifier.height(12.px)) {}
}
